import { apiProxy } from '@/api/proxy/proxy';
import SERVER_TYPES from '@/shared/servertype';
import env from '@/shared/constant/url/env';
import { getUrl } from '../utils';
import { SuccessResponse } from '@/types/response';
import { UpgradeParams } from '@/views/application-module/type';
import axios from '@/utils/request';

export const getServiceList = () =>
  apiProxy(SERVER_TYPES.SERVICE_REPOSITORY, env.GET_SERVICE_FOR_TEST, {
    method: 'GET',
  });

export const getAppList = (payload: any) =>
  apiProxy(SERVER_TYPES.SERVICE_REPOSITORY, env.GET_APP_FOR_TEST, {
    method: 'GET',
    ...payload,
  });

export const deployToEnv = (type: 'app' | 'service', params: any) =>
  apiProxy(SERVER_TYPES.SERVICE_REPOSITORY, getUrl(env.DEPLOY_TO_TEST, type), {
    method: 'POST',
    ...params,
  });

export const getAvailableUpgradeInstance: (
  type: 'app' | 'service',
  instanceIds: number[],
) => Promise<SuccessResponse<{ [key: string]: boolean }>> = (type, instanceIds) =>
  apiProxy(
    SERVER_TYPES.SERVICE_REPOSITORY,
    `${getUrl(env.UPGRADE_AVAILABLE, type)}?${instanceIds.map((e) => `instanceIds=${e}`).join('&')}`,
    {
      method: 'GET',
    },
  );

export const getUgradeVersions: (
  type: 'app' | 'service',
  instanceId: number | undefined,
) => Promise<SuccessResponse<string[]>> = (type, instanceId) =>
  apiProxy(SERVER_TYPES.SERVICE_REPOSITORY, getUrl(env.UPGRADE_VERSIONS, type), {
    method: 'GET',
    params: {
      type,
      instanceId,
    },
  });

export const doUpgradeInstance: (payload: UpgradeParams) => Promise<SuccessResponse<null>> = (payload) =>
  apiProxy(SERVER_TYPES.SERVICE_REPOSITORY, getUrl(env.UPGRADE_INSTANCE, payload.type), {
    method: 'POST',
    params: payload,
  });

// 部署相关
export const getServiceListForDeploy = () =>
  apiProxy(SERVER_TYPES.SERVICE_REPOSITORY, env.GET_SERVICE_FOR_DEPLOY, {
    method: 'GET',
  });

export const getAppListForDeploy = (payload: any) =>
  apiProxy(SERVER_TYPES.SERVICE_REPOSITORY, env.GET_APP_FOR_DEPLOY, {
    method: 'GET',
    ...payload,
  });

// 获取部署申请列表
export const getDeployApplyList = (payload: any): Promise<SuccessResponse<any>> =>
  axios.get(getUrl(env.GET_DEPLOY_LIST), { params: payload });

// 新增部署申请
export const addDeployApply = (payload: any): Promise<SuccessResponse<any>> =>
  axios.post(getUrl(env.ADD_DEPLOY), payload);

// 编辑部署申请
export const updateDeployApply = (id: string, payload: any): Promise<SuccessResponse<any>> =>
  axios.post(getUrl(env.UPDATE_DEPLOY, id), payload);

// 删除部署申请
export const deleteDeployApply = (id: string): Promise<SuccessResponse<any>> =>
  axios.post(getUrl(env.DELETE_DEPLOY, id));

// 获取审核列表
export const getDeployAuditList = (payload: any): Promise<SuccessResponse<any>> =>
  axios.get(getUrl(env.GET_AUDIT_LIST), { params: payload });

// 审核部署申请
export const auditDeployApply = (id: string, payload: any): Promise<SuccessResponse<any>> =>
  axios.post(getUrl(env.AUDIT_DEPLOY, id), payload);

// 获取申请人或审核人列表
export const getApplyOrAuditUser = (payload?: any): Promise<SuccessResponse<any>> =>
  axios.get(getUrl(env.GET_USER), { params: payload });
