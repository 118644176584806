
import { computed, defineComponent, PropType, reactive, ref } from 'vue';
import { useVersionsList } from './useUpgrade';
import { doUpgradeInstance } from '@/api/env';
import { ElForm, ElMessage } from 'element-plus';
import { ServiceInstanceType } from '../../type';
const INSTANCE_UPGRADE_SUCCESS_MSG_MAP: Record<ServiceInstanceType, string> = {
  app: '应用',
  service: '服务版本已经升级成功，请检查配置后启动新版本',
};
export default defineComponent({
  name: 'UpgradeVersion',
  props: {
    id: {
      type: Number as PropType<number>,
      required: true,
    },
    type: {
      type: String as PropType<ServiceInstanceType>,
      default: 'app',
    },
    visible: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  emits: ['update:visible'],
  setup(props, { emit }) {
    const formData = reactive({
      toVersion: '',
    });

    const rules = reactive({
      toVersion: [
        {
          required: true,
          message: '请选择新版本',
          trigger: 'blur',
        },
      ],
    });

    const formRef = ref<InstanceType<typeof ElForm>>();

    const dialogVisible = computed({
      get() {
        return props.visible;
      },
      set(v) {
        emit('update:visible', v);
      },
    });

    const { loading: loadingVersions, data: versions } = useVersionsList({
      instanceId: props.id,
      type: props.type,
    });

    const upgradeLoading = ref(false);

    const doUpgrade = async () => {
      upgradeLoading.value = true;
      try {
        const validRes = await formRef.value?.validate();
        if (!validRes) {
          throw new Error('表单验证失败');
        }
        const { code } = await doUpgradeInstance({
          instanceId: props.id,
          toVersion: formData.toVersion,
          type: props.type,
        });
        if (code === 0) {
          const msg = INSTANCE_UPGRADE_SUCCESS_MSG_MAP[props.type];
          ElMessage.success(msg);
        } else {
          ElMessage.error('升级失败');
        }
      } catch (error) {
        console.log(error);
      } finally {
        dialogVisible.value = false;
        upgradeLoading.value = false;
      }
    };

    const disabledUpgrade = computed(() => formData.toVersion === '' || upgradeLoading.value);

    return {
      loadingVersions,
      versions,
      formData,
      dialogVisible,
      doUpgrade,
      upgradeLoading,
      disabledUpgrade,
      formRef,
      rules,
    };
  },
});
