import { getUgradeVersions } from '@/api/env';
import { ref } from 'vue';
import { GetVersionsParams } from '../../type';
import { useFetch } from './useFetch';
export function useVersionsList({ instanceId, type }: GetVersionsParams): ReturnType<typeof useFetch> {
  if (!instanceId) {
    return {
      loading: ref(false),
      data: ref<string[]>(),
    } as any;
  }
  return {
    ...useFetch<string[]>(() => getUgradeVersions(type, instanceId)),
  };
}
