import { toDisplayString as _toDisplayString, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Teleport as _Teleport, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-222de1e4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "el-icon-top upgrade__icon" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UpgradeVersion = _resolveComponent("UpgradeVersion")!

  return (_openBlock(), _createElementBlock("span", {
    onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleClick && _ctx.handleClick(...args))),
    class: "version-text",
    role: "button",
    tabindex: "0"
  }, [
    _createTextVNode(_toDisplayString(_ctx.data?.serviceVersion) + " ", 1),
    _withDirectives(_createElementVNode("i", _hoisted_1, null, 512), [
      [_vShow, _ctx.upgradeAvailable]
    ]),
    (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
      (_ctx.isRefrenceInstance && _ctx.getShowBool('upgrade'))
        ? (_openBlock(), _createBlock(_component_UpgradeVersion, {
            key: 0,
            id: _ctx.data.id,
            type: _ctx.type,
            visible: _ctx.visible,
            "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.visible) = $event))
          }, null, 8, ["id", "type", "visible"]))
        : _createCommentVNode("", true)
    ]))
  ]))
}