
import { getAvailableUpgradeInstance } from '@/api/env';
import { getShowBool } from '@/utils/permission-show-module';
import {
  checkRefrenceServiceBySource,
  SERVICE_SOURCE,
} from '@/views/service-management/business-service/utils/permisson';
import { defineComponent, PropType, ref, toRefs, watchEffect, computed } from 'vue';
import { App, ServiceInstanceType } from '../../type';
import UpgradeVersion from './UpgradeVersion.vue';

export default defineComponent({
  name: 'Version',
  props: {
    data: {
      type: Object as PropType<App>,
      requried: true,
      default: () => ({}),
    },
    alowUpgrade: {
      // if show upgrade diaglog, default not show
      type: Boolean as PropType<boolean>,
      default: false,
    },
    type: {
      type: String as PropType<ServiceInstanceType>,
      default: 'app',
    },
  },
  components: { UpgradeVersion },
  setup(props) {
    const visible = ref(false);
    const upgradeAvailable = ref(false);
    const { data: instance, type } = toRefs(props);
    const isRefrenceInstance = computed(() => instance.value.serviceSource === SERVICE_SOURCE.REFRENCE);
    watchEffect(async () => {
      if (!checkRefrenceServiceBySource(instance.value.serviceSource)) {
        // not refrence service skip checking upgrade available
        return false;
      }
      if (!getShowBool('upgrade')) {
        return false;
      }
      try {
        const { code, data } = await getAvailableUpgradeInstance(type.value, [instance.value.id]);
        if (code === 0) {
          upgradeAvailable.value = data[instance.value.id] ?? false;
        }
      } catch (error) {
        console.log(error);
      }
    });
    const handleClick = () => {
      if (upgradeAvailable.value && props.alowUpgrade) {
        visible.value = true;
      }
    };
    return {
      visible,
      handleClick,
      isRefrenceInstance,
      upgradeAvailable,
      getShowBool,
    };
  },
});
