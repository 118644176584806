import { SuccessResponse } from '@/types/response';
import { ref, watchEffect, Ref } from 'vue';
import { HttpRequestApi } from '../../type';
export function useFetch<T>(api: HttpRequestApi<T>, loading?: Ref<boolean>) {
  const responseData = ref<T>();
  const loadingRef = loading ?? ref(false);
  const response = ref<SuccessResponse<T>>();
  watchEffect(async () => {
    try {
      loadingRef.value = true;

      const res = await api();

      response.value = res;
      const { code, data, message } = res;
      if (code === 0) {
        responseData.value = data;
      } else {
        throw new Error(message);
      }
    } catch (error) {
      console.log(error);
    } finally {
      loadingRef.value = false;
    }
  });
  return {
    loading: loadingRef,
    data: responseData,
    response,
  };
}
